import React from 'react'
import picturenext from '../../assets/imgs/nextbanner.png'
import pictureact from '../../assets/imgs/reactbanner.png'
import pictureastro from '../../assets/imgs/astrobanner.png'
import picturefirebase from '../../assets/imgs/firebasebanner2.png'
import picturepostgres from '../../assets/imgs/postgreslogo.png'
import picturesupabase from '../../assets/imgs/logosupa.webp'
import {CommandDialogDemo} from '../../components/CommandDialogDemo'

function Introduction() {
  
  return (
    <React.Fragment>
      <article className='nextra-body relative pb-8 w-full max-w-full flex min-w-0 pr-[calc(env(safe-area-inset-right)-1.5rem)] nextra-body-typesetting-default'>
           <main className='changeame mx-auto max-w-4xl px-6 md:px-8 pt-4 z-10 min-w-0 w-full'>
             <div className='nextra-breadcrumb text-sm font-normal flex mt-2.5 text-gray-500 transition-colors cursor-default overflow-hidden'>
               <div className='transition-colors hover:text-gray-900 dark:hover:text-gray-200 text-ellipsis whitespace-nowrap overflow-hidden min-w-[24px]'>
                 All Articles
               </div>
               <span className='mx-2 select-none'>/</span>
               <div className='transition-colors hover:text-gray-900 dark:hover:text-gray-200 text-gray-600 dark:text-gray-400 active'>
                 Introduction
               </div>
             </div>
           <h1>Building your website!!</h1>
             <h2>
               <span className="subheading-anchor" id="Main"></span>
               <a href="#Main" className="anchor text-current no-underline no-outline">Main<span className="anchor-icon" aria-hidden="true">#</span></a>
             </h2>
             <div className='w-auto grid grid-cols-1 sm:grid-cols-2 gap-4 p-4'>
             <p>
             You do not have to worry about technologies as we are going to help you utterly with it, as savvies on <code>software-programming.</code> we promise to handle all the technical aspects of your website. 
             Currently we are working on projects using technologies and frameworks like <code>React</code>, <code>Next.js</code>, <code>Astro</code> as well as simply javascript libraries in order to get your <code>FrontEnd</code> done.
             You can also count on us to help you with the entire design aspect of your website, obviously bearing in mind your preferences and the image you want to project, highlighting the whole aesthetic spect an app & website have to have in its infraestructure.  
             </p>
             <div className="w-auto px-2 pt-16 pb-8 mx-auto sm:pt-24 lg:px-8">
               <div className="w-auto">
                  <div className='w-72 h-72 rounded-lg shadow-lg overflow-hidden bg-gray-100 dark:bg-dark dark:bg-opacity-50 justify-center border border-transparent transition-all duration-300 hover:shadow-[0_0_20px_5px_rgba(58,199,255,0.6)] hover:border-cyan-500'>
                    <div className='m-5 relative'>
                      <img src={picturenext} alt="img" className="will-change animate w-44 h-44 absolute object-cover rounded-md z-10 top-0 left-0 hover:z-40 ease-in-out duration-500 hover:scale-110" height="auto" width="auto"/>
                      <img src={pictureact} alt="img" className="will-change animate w-44 h-44 absolute object-cover rounded-md z-20 top-8 left-8 hover:z-40 ease-in-out duration-500 hover:scale-110" height="auto" width="auto"/>
                      <img src={pictureastro} alt="img" className="will-change animate w-44 h-44 absolute object-cover rounded-md z-30 top-16 left-16 ease-in-out duration-500 hover:scale-110" height="auto" width="auto"/>
                    </div>
                  </div>
               </div>
             </div>
             </div>
             <br></br>
           <h1>Relevant BackEnd's Aspects</h1>
             <h2>
               <span className="subheading-anchor" id="Suggestion"></span>
               <a href="#Suggestion" className="anchor text-current no-underline no-outline">Suggestion<span className="anchor-icon" aria-hidden="true">#</span></a>
             </h2>
             <div className='w-auto grid grid-cols-1 sm:grid-cols-2 gap-4 p-4'>
            <div className="w-auto px-2 pt-16 pb-8 mx-auto sm:pt-24 lg:px-8">
               <div className="w-auto grid grid-cols-1">
                 <div className='w-72 h-72 rounded-lg shadow-lg overflow-hidden bg-gray-100 dark:bg-dark dark:bg-opacity-50 justify-center border border-transparent transition-all duration-300 hover:shadow-[0_0_20px_5px_rgba(58,199,255,0.6)] hover:border-cyan-500'>
                    <div className='m-5 relative'>
                      <img src={picturefirebase} alt="img" className="will-change animate w-44 h-44 absolute object-cover rounded-md z-10 top-0 right-0 hover:z-40 ease-in-out duration-500 hover:scale-110" height="auto" width="auto"/>
                      <img src={picturepostgres} alt="img" className="will-change animate w-44 h-44 absolute object-cover rounded-md z-20 top-8 right-8 hover:z-40 ease-in-out duration-500 hover:scale-110" height="auto" width="auto"/>
                      <img src={picturesupabase} alt="img" className="will-change animate w-44 h-44 absolute object-cover rounded-md z-30 top-16 right-16 ease-in-out duration-500 hover:scale-110" height="auto" width="auto"/>
                    </div>
                  </div> 
               </div>
             </div>
             <p>
             In order to build a complete website and application we recommend to develop a complete application and, that implies the <code>BackEnd</code> that the application will use in order to handle all the data and information that the <code>FrontEnd</code> will display.
              That is the reason We are currently working with <code>Firebase</code> as a <code>BackEnd</code> solution, as well as <code>PostgreSQL</code> as a database and <code>Supabase</code> as a solution for the <code>BackEnd</code> of the application you will need. 
              No matter the complexity of the application you want to develop, we are here to help you with the <code>all-process</code> of your application. 
             </p>
             <div className='hidden ...'>
             <CommandDialogDemo />
             </div>
              </div>
             <div className='mt-16'></div>
             <div
               className='nextra-navigation-links pt-8 mb-8 border-t dark:border-neutral-800 flex flex-row items-center justify-between'
             >
               <div className='flex-1 min-w-0'></div>
               <div className='flex-1 min-w-0 text-right'>
                 <a
                   className='w-full text-lg font-medium p-4 -m-4 no-underline transition-colors text-gray-600 dark:text-gray-300 dark:hover:text-primary-500 hover:text-primary-500 inline-flex items-center justify-end rounded ml-2'
                   title='Next'
                   href='/Firstproject'
                   >💻 eCommerce <svg
                     fill='none'
                     viewBox='0 0 24 24'
                     stroke='currentColor'
                     height='24'
                     className='transform inline flex-shrink-0 ml-1'
                   >
                     <path
                       stroke-linecap='round'
                       stroke-linejoin='round'
                       stroke-width='2'
                       d='M9 5l7 7-7 7'
                     ></path></svg></a>
               </div>
             </div>
           </main>
           <div className='nextra-toc w-64 hidden xl:block text-sm px-4'>
             <div
               className='nextra-toc-content overflow-y-auto pr-4 -mr-4 sticky max-h-[calc(100vh-4rem-env(safe-area-inset-bottom))] top-16 pt-8'
             >
               <ul>
                 <p className='font-semibold tracking-tight mb-4'>
                   On This Page
                 </p>
                 <li className='scroll-py-6 scroll-my-6'>
                   <a
                     href='#Main'
                     className='no-underline inline-block font-semibold text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300'
                     >Main</a>
                 </li>
                 <li className='scroll-py-6 scroll-my-6'>
                   <a
                     href='#Suggestion'
                     className='no-underline inline-block font-semibold text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300'
                     >Suggestion</a>
                 </li>
               </ul>
               <div className='nextra-toc-meta border-t mt-8 pt-8 shadow-[0_-12px_16px_white] dark:shadow-[0_-12px_16px_#111] bg-white dark:bg-dark sticky pb-8 bottom-0 dark:border-neutral-800'> 
               </div>
               </div>
             </div>
        </article>
    </React.Fragment>
  )
}

export default Introduction
