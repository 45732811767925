import React from 'react'

const Termsconditions = () => {
  return (
    <div className='m-7 text-base'>
        
        <h1>Terms and Conditions</h1>
        <h2>Effective Date:  2022-01-01</h2>
        <p>
        Welcome to Nala Business, an international provider of software development, e-commerce migration, and marketplace solutions. These Terms and Conditions outline the rules and regulations for the use of Nala Business' website and services.

        By accessing or using our services, you agree to these terms. If you do not agree with any of these terms, you must stop using our website and services.
        </p>
        <ul>
                <li>
                        <h2>1. Services</h2>
                        <p>
                        Nala Business provides software development services, including application development, web development, and migration services to e-commerce platforms and various marketplaces. These services are available to clients worldwide, with project-specific terms provided in individual service agreements.
                        </p>
                </li>
                <li>
                        <h2>2. Eligibility</h2>
                        <p>
                        To use our services, you must be at least 18 years old and have the legal capacity to enter into contracts. By using our website or services, you represent and warrant that you meet this eligibility requirement.
                        </p>
                </li>
                <li>
                        <h2>3. Intellectual Property</h2>
                        <p>
                        All intellectual property rights in the content, design, code, and any other material associated with Nala Business are owned by us or our licensors. You are granted a limited license to use the materials provided strictly for personal, non-commercial use, unless otherwise agreed upon in writing and, apart from those components that were taken by open sources in code frameworks.
                        </p>
                </li>
                <li>
                        <h2>4. Use of Services</h2>
                        <p>
                        Clients must comply with all applicable laws and regulations when using our services. Unauthorized use of our systems or services may result in termination of access and legal action.
                        </p>
                </li>
                <li>
                        <h2>5. Payment and Fees</h2>
                        <p>
                        Payment for services will be governed by the specific service agreement entered between Nala Business and the client. Payments are due upon the agreed schedule, and failure to make timely payments may result in suspension or termination of services.
                        </p>
                </li>
                <li>
                        <h2>6. Limitation of Liability</h2>
                        <p>
                        Nala Business will not be liable for any indirect, incidental, or consequential damages resulting from the use or inability to use our services, including but not limited to data loss or delays in business operations.
                        </p>
                </li>
                <li>
                        <h2>7. Confidentiality</h2>
                        <p>
                        We take confidentiality seriously. All information provided to us during the service engagement will be kept confidential and only used as necessary to fulfill our service obligations.
                        </p>
                </li>
                <li>
                        <h2>8. Termination</h2>
                        <p>
                        Nala Business reserves the right to terminate or suspend services at any time if there is a breach of these Terms or if we suspect misuse of our platform.
                        </p>
                </li>
                <li>
                        <h2>9. Changes to Terms</h2>
                        <p>
                        We may update these Terms at any time. Continued use of our services after any changes will signify your acceptance of the updated Terms.
                        </p>
                </li>
                <li>
                        <h2>10. Governing Law</h2>
                        <p>
                        These Terms shall be governed by and construed in accordance with the laws of the Jurisdiction you are using or contracting the web services from, without regard to conflict of law principles.
                        </p>
                </li>
        </ul>

        <br/>
        <h1> Privacy Policy </h1>
        <h2>Effective Date:  2022-01-01</h2>
        <p>
        Nala Business ("we", "us", "our") respects your privacy and is committed to protecting the personal information you provide to us. This Privacy Policy explains how we collect, use, and disclose your personal data.
        </p>
        <ul>
                <li>
                        <h2>1. Information We Collect</h2>
                        <p>
                        We may collect the following types of personal information from you:
                        - Name, email address, and contact information.
                        - Company details and project requirements.
                        - Payment and billing information.
                        - Technical information such as IP addresses and usage data when you visit our website.
                        </p>
                </li>
                <li>
                        <h2>2. How We Use Your Information</h2>
                        <p>
                        We use your personal information to:
                        - Provide and improve our software development and e-commerce services.
                        - Process transactions and manage billing.
                        - Communicate with you about projects, updates, or support.
                        - Analyze website usage and improve our online experience.
                        - Comply with legal obligations.
                        </p>
                </li>
                <li>
                        <h2>3. Sharing of Information</h2>
                        <p>
                        We do not sell or rent your personal information. However, we may share your data with:
                        - Service providers that assist in fulfilling our services <code>e.g., payment processors</code>.
                        - Legal authorities if required by law.
                        - Business partners when collaborating on joint projects.
                        </p>
                </li>
                <li>
                        <h2>4. Data Security</h2>
                        <p>
                        We take reasonable steps to protect the personal information we collect from loss, misuse, unauthorized access, disclosure, alteration, or destruction. However, no online transmission is completely secure, and we cannot guarantee absolute security.
                        </p>
                </li>
                <li>
                        <h2>5. Your Rights</h2>
                        <p>
                        Depending on your location, you may have certain rights regarding your personal data, including the right to access, correct, or delete the information we hold about you. To exercise these rights, please contact us at <code>Nalabusiness1995@gmail.com</code>.
                        </p>
                </li>
                <li>
                        <h2>6. Data Retention</h2>
                        <p>
                        We retain your personal data only as long as necessary to provide our services or as required by law. Afterward, we will securely delete or anonymize the data.
                        </p>
                </li>
                <li>
                        <h2>7. Cookies</h2>
                        <p>
                        Our website uses cookies and similar technologies to improve your experience and gather analytical data. You can control cookie preferences in your browser settings.
                        </p>
                </li>
                <li>
                        <h2>8. International Data Transfers</h2>
                        <p>
                        Since Nala Business operates internationally, your personal information may be transferred across borders. We ensure that such transfers comply with applicable data protection laws.
                        </p>
                </li>
                <li>
                        <h2>9. Changes to This Policy</h2>
                        <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and continued use of our services will signify your acceptance of the updated policy.
                        </p>
                </li>
                <li>
                        <h2>10. Contact Us</h2>
                        <p>
                        If you have any questions about this Privacy Policy or your personal data, please contact us at:
                        <code>Nala Business</code>  
                        <code>Email: Nalabusiness1995@gmail.com</code>  
                        <code>Phone: 3143468608</code>  
                        </p>
                </li>
        </ul>
        <br/>
        <h1>Products & commodities sold on the web site</h1>
        <h2>Effective Date:  2022-01-01</h2>
        <ul>
                <li>
                        <h2>First</h2>
                        <p>
                        All products sold on this website can be returned if damaged between the 5 days of being purchased, because after that period of time we won’t be able to refund you and we reserve the right to keep this policy and when necessary our customer team can assist you with your requirements.
                        </p>
                </li>
                <li>
                        <h2>Second</h2>
                        <p>
                        All delates by the carriers who ship the commodities sold on this website will be responsibility of the carrier contracted as we do not provide the shipping service and if the customer wants to return something they have got to consider the returns policy expressed above.
                        </p>
                </li>
                <li>
                        <h2>Third</h2>
                        <p>
                        As we are selling products from other companies we reserve the right of not support any problem with the product itself as it is manufactured by each brand and we just distribute it by re-buying or buying back it.
                        </p>
                </li>
        </ul>
                
        <br/>
        <div>
        <h1>TERMS & CONDITIONS WEB NALA BUSINESS</h1>
        <h2>Política de privacidad</h2>
        <p>
        SECURITY IN E-COMMERCE
        Making payments through the NALA BUSINESS website is safe because:
        NALA BUSINESS has maximum security measures. The process works on a secure server using the SSL <code>Secure Socket Layer</code> protocol. The secure server establishes a connection that ensures that it is only intelligible to the Client's computer and the E-Commerce computer. In this way, by using the SSL protocol it is guaranteed:
        That the Client is communicating its data only to the NALA BUSINESS server center.
        That between the Client and the NALA BUSINESS server center the data is transmitted encrypted, preventing its possible reading or manipulation by third parties.
        From www.nalabusinesses.com, NALA BUSINESS does not capture, store or transmit transactional data, since it has the payment gateway: Mercado Pago <code>Credit Card and Efecty wompi services bancolombia and Colombian banks, as well as international gateways such as stripe, Binance, Paypal</code> certified with PCI and international gateways through checkouts, certified in high security standards that include, among others, encryption software, validation procedures and robust data protection measures.
        ACCESS TO NALA BUSINESS CONTENT
        Clients and Users are fully responsible for their conduct when accessing the information on the website while browsing it, as well as after having accessed it. As a consequence of the above, Clients and Users are the only ones responsible before NALA BUSINESS and third parties for the consequences that may arise from the use:

        For illegal purposes or effects or contrary to this document, of any content of the website, whether or not prepared by NALA BUSINESS, published or not under its name in an official manner.
        Contrary to the content of this document and harmful to the interests or rights of third parties, or that in any way may damage, render useless or deteriorate the website or its services or prevent normal use by other clients or users.
        NALA BUSINESS reserves the right to update the content when it deems appropriate, as well as to eliminate, limit or prevent access to it, temporarily or permanently, or to deny access to the website to Clients or Users who misuse the content and/or breach any of the conditions that appear in this document.

        <h1>INTELLECTUAL PROPERTY</h1>
        NALA BUSINESS has all rights to the React content <code>open source component libraries are used</code>, design, layout and source code of this website and that are included in the website. These rights are protected by current legislation regarding intellectual property.

        The total or partial reproduction of this website is expressly prohibited without the express written permission of NALA BUSINESS.

        Likewise, the copying, reproduction, adaptation, modification, distribution, commercialization, public communication and/or any other action that involves an infringement of the regulations in force in Colombia and internationally or in the rest of the world regarding intellectual and/or industrial property is strictly prohibited without the prior express written authorization of NALA BUSINESS.

        NALA BUSINESS informs that it does not grant any implicit license or authorization on the intellectual and/or industrial property rights or on any other right or property related to the personalized content of Nala business, directly or indirectly, with the content included in its website.

        <h1>RESPONSIBILITY</h1>

        NALA BUSINESS assumes no responsibility for access by minors to the content included on the website. It is the responsibility of parents or legal guardians to exercise adequate control over the activity of their children or minors in their care or to install any of the tools to control the use of the Internet, in order to prevent access to materials or content not suitable for minors, as well as the sending of personal data without the prior authorization of their parents or guardians.

        Likewise, NALA BUSINESS shall not be liable under any circumstances when the following occur:

        Errors or delays in accessing the website by the Client when entering their data in the order form or checkouts, the slowness or impossibility of receipt by the Client of the order confirmation or any anomaly that may arise when these incidents are due to problems in the Internet network, causes of force majeure and any other unforeseeable contingency beyond the good faith of NALA BUSINESS.
        Failures or incidents that may occur in communications, deletions or incomplete transmissions, so that it is not guaranteed that the website services are constantly operational.
        Errors or damages caused to the website due to inefficient and bad faith use of the service by the Client.
        </p>
        <br />
        <h1>Bear in mind</h1>
        <p> 
        Data processing, security and exclusivity:
        NALA BUSINESS guarantees that when obtaining personal data, it is for the exclusive use and access of the company and the processing given to it is reliable and secure. Authorized data will only be used by persons authorized by the company. Likewise, NALA BUSINESS has a qualified work team for the management of information, from the management of the database to technological resources for this purpose.

        <h2>Purpose of the information:</h2>
        NALA BUSINESS guarantees that the purpose of handling personal data is to ensure the normal exercise of commercial and labor relations; as well as their inherent activities.

        Rights as the owner and specific procedures:
        The rights that assist the client as the owner of the data provided are: the
        right to know, update, rectify, delete his/her personal information, to revoke the consent granted, request proof of authorization, to request and receive information about the purpose of the use of his/her data, to file complaints with the SIC for misuse of his/her data, and to be informed free of charge about the data being processed. It is clarified that in the event that it is necessary to use data that deals with sensitive data or data about girls, boys and adolescents, the Client may refrain from providing said information, understanding sensitive data to be those that affect the privacy of the Owner or whose improper use may lead to discrimination.
        </p>
        <br />
        <h2>Changes to the privacy policy</h2>
        <p> 
        Privacy Notice
        The treatment that NALA BUSINESS INC gives to your data is reliable and safe, no person provides your data by means other than: corporate domain emails, corporate website, documents duly addressed and sent by correspondence or physically granted through an official company document. The authorized data will be used only by persons authorized by the company for the purpose of carrying out the normal exercise of commercial and labor relations; as well as its inherent activities.

        The rights that assist you as the owner of the data are:

        Know, update, rectify and delete personal information.
        Revoke the consent granted.
        Request proof of authorization.
        Request and receive information about the purpose of use of the data.
        File complaints with the SIC for misuse of personal data.
        Obtain free information about the data subject to processing.
        When the data is about sensitive data or data of girls, boys and adolescents, you may refrain from responding, understanding sensitive data to be those that affect the privacy of the Holder or whose improper use may lead to discrimination.

        If you were already part of our database, it is necessary to have your authorization to continue having a record and using your data. In this order of ideas, if you are already part of our database and after 30 business days of receiving this notice you do not take the corresponding action to revoke the authorization, it is understood unequivocally and expressly that you give authorization to keep your information, the above, in accordance with law 1581 of 2012 and its regulatory decree 1377 of 2013.

        If you agree with the provisions of this policy and authorize NALA BUSINESS INCa to use your personal data, keep this information in mind for future actions. If, on the other hand, you do not wish for your data to be used by the company in any context, you can contact the persons previously designated as contact details via their email addresses.
        </p>
        </div>
    </div>
  )
}

export default Termsconditions