import React from "react";
import { BentoGrid, BentoGridItem } from "../bento-gridi";
import { IconTableColumn } from "@tabler/icons-react";
import { motion } from "framer-motion";

const SkeletonFour = () => {
  const first = {
    initial: { x: 20, rotate: -5 },
    hover: { x: 0, rotate: 0 },
  };
  const second = {
    initial: { x: -20, rotate: 5 },
    hover: { x: 0, rotate: 0 },
  };
  return (
    <motion.div
      initial="initial"
      animate="animate"
      whileHover="hover"
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-row space-x-2 backdrop-blur-3xl"
    >
      <motion.div
        variants={first}
        className="h-full w-1/3 rounded-2xl bg-transparent p-4 dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center backdrop-blur-3xl shadow-lg shadow-indigo-500/50"
      >
        <img
          src="https://pbs.twimg.com/profile_images/1417752099488636931/cs2R59eW_400x400.jpg"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-14 w-14"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-900 mt-4">
          Sensa creaciones
        </p>
        <p className="border border-red-500 bg-red-100 dark:bg-red-900 text-red-600 text-xs rounded-full px-2 py-0.5 mt-4">
          1st Customer
        </p>
      </motion.div>
      <motion.div className="h-full relative z-20 w-1/3 rounded-2xl bg-transparent p-4 dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center backdrop-blur-3xl shadow-lg shadow-indigo-500/50">
        <img
          src="https://d1ymz67w5raq8g.cloudfront.net/Pictures/460x307/9/8/2/533982_webpicsc_199996.jpg"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-14 w-14"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-gray-900 mt-4">
          All Chai 
        </p>
        <p className="border border-red-500 bg-red-100 dark:bg-red-900 text-red-600 text-xs rounded-full px-2 py-0.5 mt-4">
          2nd Customer
        </p>
      </motion.div>
      <motion.div
        variants={second}
        className="h-full w-1/3 rounded-2xl bg-transparent []:bg-white p-4 dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center backdrop-blur-3xl shadow-lg shadow-indigo-500/50"
      >
        <img
          src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTA4L2pvYjEwMzQtZWxlbWVudC0wNi0zOTcucG5n.png"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-14 w-14"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-500 mt-4 ">
          La Taqueria
        </p>
        <p className="border border-red-500 bg-red-100 dark:bg-red-900 text-red-600 text-xs rounded-full px-2 py-0.5 mt-4">
          3rd Customer
        </p>
      </motion.div>
    </motion.div>
  );
};

export function BentoGridThirdDemo() {
  return (
    <BentoGrid className="max-w-4xl mx-auto mt-8 md:auto-rows-[20rem] block backdrop-blur-3xl">
      <BentoGridItem
        title="Testimonial"
        description={(
          <span className="text-sm">
            We help you to stand out from the e-crowd.
          </span>
        )}
        header={<SkeletonFour />}
        className="md:col-span-2"
        icon={<IconTableColumn className="h-4 w-4 text-neutral-500" />}
      />
    </BentoGrid>
  );
}
