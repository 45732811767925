import React from 'react'
import gifweb from '../assets/imgs/gifweb.gif'

export const Gifweb = () => {
  return (
    <div>
        <img src={gifweb} alt="gifweb" className="w-full h-full m-3 rounded-md" />
    </div>
  )
}
