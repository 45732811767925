import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Paintbrush, Youtube, AudioWaveform, Github, ShieldAlert, DatabaseBackup, Construction, ShoppingCart, Heart } from 'lucide-react'

const timelineData = [
  { id: 1, icon: Paintbrush, title: 'Web Design', date: '2023-01-15', description: 'Description for Event 1' },
  { id: 2, icon: Github, title: 'Web Development', date: '2023-03-22', description: 'Description for Event 2' },
  { id: 3, icon: DatabaseBackup, title: 'Web Hosting', date: '2023-06-10', description: 'Description for Event 3' },
  { id: 4, icon: Construction, title: 'Web Maintenance', date: '2023-09-05', description: 'Description for Event 4' },
  { id: 5, icon: ShoppingCart, title: 'Web Marketing as E-commerce', date: '2023-11-18', description: 'Description for Event 5' },
  { id: 6, icon: ShieldAlert, title: 'Web Security', date: '2023-12-31', description: 'Description for Event 6' },
  { id: 7, icon: Heart, title: 'Web Optimization', date: '2023-12-31', description: 'Description for Event 6' },
  { id: 8, icon: AudioWaveform, title: 'Web Analytics as E-commerce', date: '2023-12-31', description: 'Description for Event 6' },
  { id: 9, icon: Youtube, title: 'Web Content', date: '2023-12-31', description: 'Description for Event 6' },
]

const VerticalTimeline = () => {
  const [selectedItem, setSelectedItem] = useState(null)

  return (
    <div className="contents mx-auto  bg-trasnparent">
      <div className="relative">
        {/* Línea vertical */}
        <div className="absolute left-4 top-0 bottom-0 w-0.5 border-l border-dotted border-stone-900"></div>

        {/* Marcador animado */}
        {selectedItem !== null && (
          <motion.div
            className="absolute left-4 w-12 h-22 bg-primary/20 rounded-full z-20 shadow-lg"
            style={{
              boxShadow: '0 0 15px 5px rgba(var(--color-primary), 0.5), 0 0 0 2px rgba(var(--color-primary), 1)',
              transform: 'translateX(-50%)',
            }}
            initial={false}
            animate={{ 
              top: `calc(${(selectedItem / (timelineData.length - 1)) * 100}%)`,
              scale: [1, 1.2, 1],
            }}
            transition={{ 
              top: { type: 'spring', stiffness: 300, damping: 30 },
              scale: { repeat: Infinity, duration: 2 },
            }}
          />
        )}

        {/* Items de la línea de tiempo */}
        {timelineData.map((item, index) => (
          <motion.div
            key={item.id}
            className={`mb-0 flex items-center cursor-pointer group items-center ${selectedItem === index ? 'scale-105' : ''}`}
            onClick={() => setSelectedItem(index)}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
          >
            <div className={`flex mt-9 items-center justify-center w-8 h-4 rounded-full bg-primary text-primary-foreground z-30 transition-all duration-300 ease-in-out ${selectedItem === index ? 'ring-4 ring-primary/30 shadow-lg shadow-primary/50' : ''}`}>
              <item.icon size={18} />
            </div>
            <div className="ml-6">
              <h3 className={`text-lg font-semibold transition-colors duration-300 ${selectedItem === index ? 'text-primary' : 'text-gray-900 dark:text-gray-100 group-hover:text-primary'}`}>
                {item.title}
              </h3>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default VerticalTimeline

