// src/ui/bento-grid.jsx
import React from "react";

export const BentoGrid = ({ children, className }) => {
  return <div className={`grid grid-cols-1 md:grid-cols-2 gap-4${className}`}>{children}</div>;
};

export const BentoGridItem = ({ title, description, header, className }) => {
  return (
    <div className={`block w-full p-4 rounded-lg ${className}`}>
      {/* {icon && <div className="mb-2">{icon}</div>} */}
      {description && <p className="mt-2 mb-3 text-sm text-black">{description}</p>}
      <h3 className="text-lg font-semibold text-black">{title}</h3>
      {header && <div className="mt-2 text-black">{header}</div>}
    </div>
  );
};
